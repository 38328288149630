import {RoleEnum, RoleLabels} from "../../../fusina-authz/entities/RoleEnum";
import {UserGrant} from "../../../fusina-authz/entities/UserGrants";
import {useForceUpdate} from "@fluentui/react-hooks";
import {
    ActionButton,
    ComboBox,
    DetailsList,
    DialogType,
    Dropdown,
    IColumn,
    IComboBoxOption,
    IconButton,
    IDropdownOption,
    IIconProps,
    MessageBar,
    MessageBarType,
    SelectableOptionMenuItemType,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Text,
    useTheme
} from "@fluentui/react";
import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {useFusinaRmiResource} from "../hooks/useFusinaRmiResource";
import {SimpleDialog} from "./SimpleDialog";
import {ITPSelectionFlow} from "./ITPSelectionFlow";
import type {ITPFilterCriteria} from "../../../fusina-itp/entities/ITPFilterCriteria";
import {MaybeErrorMessageBar} from "./MaybeErrorMessageBar";
import {Stack} from "@fluentui/react/lib/Stack";

const userRolesColumns: IColumn[] = [
    {
        name: 'Ambito',
        key: 'scopeId',
        fieldName: 'scopeId',
        minWidth: 200,
        targetWidthProportion: 0.45,
    },
    {
        name: 'Ruolo',
        key: 'role',
        fieldName: 'role',
        minWidth: 180,
        targetWidthProportion: 0.45,
    },
    {
        name: '',
        key: 'buttons',
        minWidth: 20,
        targetWidthProportion: 0.1,
    },
]

const addRoleIcon: IIconProps = {iconName: 'AddFriend'};
const delRoleIcon: IIconProps = {iconName: 'UserRemove'};

const roleOptions: IDropdownOption[] = [
    RoleEnum.CDL,
    RoleEnum.CM202311,
    RoleEnum.SM,
    RoleEnum.PM,
    RoleEnum.CDL_Q,
    RoleEnum.Appaltatore,
    RoleEnum.AppaltatoreCQ,
    RoleEnum.Supervisor20220713,
    RoleEnum.OdSCompiler202209,
    RoleEnum.SupervisorQ202310,
    // RoleEnum.QObserver202310, // Let's have a specific UI only for itp-scoped.
    RoleEnum.JobReadAccess202310,
].map(r => ({
    key: r,
    text: RoleLabels[r],
}))

const itpScopedRoleOptions: IDropdownOption[] = [
    RoleEnum.QObserver202310,
].map(r => ({
    key: r,
    text: RoleLabels[r],
}))

export const UserGrantsEditor: React.FC<{
    grants: UserGrant[]
    onChange?: () => void
}> = props => {
    const {data: storedCompanies} = useFusinaRmiResource('JobsAdmin', 'listCompanies')
    const {data: storedJobs} = useFusinaRmiResource('JobsAdmin', 'listJobs')

    const scopeOptions = useMemo<IComboBoxOption[]>(() => (storedCompanies && storedJobs) ? [
        {key: 'companies', text: 'Imprese', itemType: SelectableOptionMenuItemType.Header},
        ...storedCompanies?.map?.(company => ({
            key: company._id as string,
            text: company.denomination,
            data: {scopeType: 'company', scopeId: company._id},
        })),
        {key: 'jobs', text: 'Commesse', itemType: SelectableOptionMenuItemType.Header},
        ...storedJobs?.map?.(job => ({
            key: job._id as string,
            text: job.code,
            data: {scopeType: 'job', scopeId: job._id},
        })),

        {key: 'itps', text: 'PCQ', itemType: SelectableOptionMenuItemType.Header},
        {key: 'select_itp', text: 'Seleziona un PCQ...'},
    ] : undefined, [storedCompanies, storedJobs])

    const [alteringGrantItem, setAlteringGrantItem] = useState<UserGrant>(undefined)
    const [isSelectingItp, setIsSelectingItp] = useState<boolean>(false)
    // NOTE: alteringGrantItem shall be imperatively modified (with no state change) after isSelectingItp is set.

    const forceUpdate = useForceUpdate();

    const _renderItemColumn = useCallback((item: UserGrant, index: number, column: IColumn) => {
        if (column.key === 'scopeId') {
            if (item.scopeType === 'itp') {
                return <ITPLabel itpId={item.scopeId}/>
            }
            return <ComboBox
                className="UserGrantsEditor-item-scopeId"
                onResolveOptions={() => scopeOptions}
                options={scopeOptions}
                selectedKey={item.scopeId ?? null}
                dropdownWidth={300}
                onChange={(ev, newValue) => {
                    if (newValue.key === 'select_itp') {
                        setAlteringGrantItem(item)
                        setIsSelectingItp(true)
                        return
                    }
                    if (!newValue.data) {
                        return
                    }
                    item.scopeType = newValue.data.scopeType
                    item.scopeId = newValue.data.scopeId
                    forceUpdate()
                    props.onChange?.()
                }}/>
        }
        if (column.key === 'role') {
            if (item.role === RoleEnum.Admin) {
                return item.role
            }
            return <Dropdown
                className="UserGrantsEditor-item-role"
                options={item.scopeType === 'itp' ? itpScopedRoleOptions : roleOptions}
                selectedKey={item.role}
                dropdownWidth={220}
                onChange={(ev, opt) => {
                    item.role = opt.key ? RoleEnum[opt.key] : ''
                    forceUpdate()
                    props.onChange?.()
                }}
            />
        }
        if (column.key === 'buttons') {
            return <>
                <IconButton
                    className="UserGrantsEditor-item-removeBtn"
                    iconProps={delRoleIcon}
                    onClick={() => {
                        props.grants.splice(index, 1)
                        forceUpdate()
                        props.onChange?.()
                    }}/>
            </>
        }
        return null
    }, [scopeOptions])

    console.debug({scopeOptions, grants: props.grants})

    if (scopeOptions === undefined) {
        return <Spinner size={SpinnerSize.medium}/>
    }

    return <>
        <DetailsList
            compact
            selectionMode={SelectionMode.none}
            items={[...props.grants]}
            columns={userRolesColumns}
            onRenderItemColumn={_renderItemColumn}
        />

        {props.grants?.some?.(g => g.scopeType === 'itp') &&
            !props.grants?.some?.(g => g.role === RoleEnum.JobReadAccess202310) &&
            <MessageBar messageBarType={MessageBarType.warning}>
                I ruoli in ambito PCQ richiedono che sia configurato anche l'accesso di base alla commessa
            </MessageBar>}

        <Stack horizontal>
            <ActionButton
                iconProps={addRoleIcon}
                onClick={() => {
                    props.grants.push({
                        scopeType: 'company',
                        scopeId: undefined,
                        role: undefined,
                    })
                    forceUpdate()
                    props.onChange?.()
                }}
            >
                Aggiungi ruolo
            </ActionButton>
        </Stack>

        {isSelectingItp && <SimpleDialog
            title="Selezione ambito singolo PCQ"
            subText={undefined}
            onDismiss={() => {
                setIsSelectingItp(false)
                forceUpdate()
            }}
            // veryEasyDismiss={true}
            isBlocking={false}
            type={DialogType.close}
            noMaxWidth
        >
            <ITPSelectionFlow
                onItpSelected={itp => {
                    alteringGrantItem.scopeType = 'itp'
                    alteringGrantItem.scopeId = itp._id
                    alteringGrantItem.role = RoleEnum.QObserver202310
                    // Let's add the required grant to read the parent job, if not already present
                    const job = storedJobs?.find?.(j => j._id && (j._id === itp?.job?._id))
                    if (job) {
                        const hasJobAccess = props.grants?.some?.(g =>
                            g.role === RoleEnum.JobReadAccess202310 && (
                                (g.scopeType === 'job' && g.scopeId === job?._id) ||
                                (g.scopeType === 'company' && (g.scopeId === job?.contractor || g.scopeId === job?.buyer))))
                        if (!hasJobAccess) {
                            props.grants.push({
                                scopeType: 'job',
                                scopeId: job._id,
                                role: RoleEnum.JobReadAccess202310,
                            })
                        }
                    } else {
                        console.error('Job not found for selected PCQ')
                    }
                    // Finish
                    setIsSelectingItp(false)
                    forceUpdate()
                    props.onChange?.()
                }}
            />
        </SimpleDialog>}
    </>
}

export const ITPLabel: React.FC<{
    itpId: string
}> = props => {
    const theme = useTheme()
    const filter = useMemo<ITPFilterCriteria>(() => ({by: 'itp', itp: {_id: props.itpId}}), [props.itpId])
    const {
        data, error
    } = useFusinaRmiResource('ITP', 'find', filter)

    if (error) {
        return <MaybeErrorMessageBar error={error}/>
    }

    if (data === undefined) {
        return <Spinner size={SpinnerSize.xSmall}/>
    }

    return <Text block style={{
        padding: '5px 8px 6px',
        border: '1px solid ' + theme.semanticColors.inputBorder,
        borderRadius: 2
    }}>
        {data?.[0]?.docN}
    </Text>
}
