import type {PolicyContext} from "../../fusina-policies/PolicyContext";
import type {InspectionTestPlan} from "../entities/InspectionTestPlan";
import type {Job} from "../../fusina-jobs/entities/Job";
import {InvalidContextException} from "../../fusina-policies/exceptions";
import {RoleEnum} from "../../fusina-authz/entities/RoleEnum";
import {getJobRoles} from "../../fusina-jobs/controls/JobGrants";
import {ITPValidator} from "./ITPValidator";


export interface ITPPolicyContext extends PolicyContext {
    job: Job,
    itp: InspectionTestPlan,
    signatureRole?: RoleEnum
}


/** Policy that limits the operations on an ITP */
export function ITPPolicy(ctx: ITPPolicyContext) {
    const sc = ctx.securityContext;

    if (!sc || !ctx.job?._id || ctx.job._id !== ctx.itp?.job?._id) {
        throw new InvalidContextException('No SC, no job id, or job id mismatch')
    }

    const myRoles: Set<RoleEnum> = new Set([
        ...getJobRoles(ctx.job, sc?.user?.grants),
        ...ctx.securityContext?.user?.globalRoles ?? [],
        ...(sc?.user?.grants?.filter?.(g => g.scopeType === 'itp' && g.scopeId === ctx.itp?._id)
            ?.map?.(g => g.role) ?? []),
    ])

    const isClient_any = myRoles.has(RoleEnum.CDL_Q) || myRoles.has(RoleEnum.CDL) || myRoles.has(RoleEnum.CM202311) || myRoles.has(RoleEnum.PM) || myRoles.has(RoleEnum.SM)
        || myRoles.has(RoleEnum.Supervisor20220713) || myRoles.has(RoleEnum.SupervisorQ202310)
    const isClient_CQ = myRoles.has(RoleEnum.CDL_Q)

    const isContractor_any = myRoles.has(RoleEnum.Appaltatore) || myRoles.has(RoleEnum.AppaltatoreCQ)
    const isContractor_ODSNI = myRoles.has(RoleEnum.Appaltatore)
    const isContractor_CQ = myRoles.has(RoleEnum.AppaltatoreCQ)

    const isQObserver = myRoles.has(RoleEnum.QObserver202310)

    const isAdmin = myRoles.has(RoleEnum.Admin)

    const isSignedByContractor = !!ctx.itp.signatures.find(s => s.role === RoleEnum.AppaltatoreCQ)
    const isSignedByClient = !!ctx.itp.signatures.find(s => s.role === RoleEnum.CDL_Q)
    const isSignedByMe = !!ctx.itp.signatures.find(s => s.userId === sc.userId)
    const isNotSigned = ctx.itp.signatures.length <= 0
    const isAlreadySignedBySameSignatureRole = !!ctx.itp.signatures.find(s => s.role === ctx.signatureRole)

    const isOwnerOrNoOwner = ctx.itp.owner === undefined ? true : (ctx.itp.owner._id === (sc.user?._id ?? sc.userId))

    const validator = new ITPValidator()
    const validationMsgN = validator.validate(ctx.itp).length

    const isInitialRev = ctx.itp?._id === (ctx.itp?.revId ?? ctx.itp._id)

    return {
        myRoles,

        isInitialRev,
        isAdmin,

        isClient_any,
        isClient_CQ,

        isContractor_any,
        isContractor_ODSNI,
        isContractor_CQ,
        isOwnerOrNoOwner,

        isQObserver,

        isSignedByContractor,
        isSignedByClient,
        isSignedByMe,
        isNotSigned,
        isAlreadySignedBySameSignatureRole,

        validationMsgN,

        permissions: {
            read: (isOwnerOrNoOwner && isContractor_any) ||
                (isSignedByContractor && isQObserver) ||
                (isSignedByContractor && isContractor_ODSNI) ||
                (isSignedByContractor && isClient_any) ||
                (isSignedByContractor && isAdmin),
            write: isContractor_CQ && isNotSigned && isOwnerOrNoOwner,
            sign: !isSignedByMe &&
                !isAlreadySignedBySameSignatureRole &&
                validationMsgN <= 0 &&
                ((isContractor_CQ && isOwnerOrNoOwner) ||
                    (isSignedByContractor && isClient_CQ)) &&
                myRoles.has(ctx.signatureRole),
            reject: (isContractor_CQ && isSignedByContractor && !isSignedByClient && isOwnerOrNoOwner) ||
                (isSignedByContractor && isClient_CQ && !isSignedByClient) ||
                (isSignedByContractor && isAdmin && isInitialRev), // NOTE: isInitialRev for simplicity (task #2650)
        }
    }
}
