import {InfoOdS} from "./InfoOds";
import {EcoOdS} from "./EcoOdS";
import {UltAccOdS} from "./UltAccOdS";
import {NI} from "./NI";
import {Job} from "../../fusina-jobs/entities/Job";
import {RoleEnum} from "../../fusina-authz/entities/RoleEnum";

export type Letter =
    | InfoOdS
    | EcoOdS
    | UltAccOdS
    | NI

export const LetterTypeLabels: Record<Letter['type'], string> = {
    EcoOdS: "Ordine di Servizio tecnico/economico",
    InfoOdS: "Ordine di Servizio informazione/comunicazione",
    UltAccOdS: "Ordine di Servizio ultimazione/accettazione lavori",
    NI: "Nota Impresa",
}

export const LetterTypeSupAbbrLabels: Record<Letter['type'], string> = {
    EcoOdS: "OdS",
    InfoOdS: "OdS",
    UltAccOdS: "OdS",
    NI: "NI",
}

export const LetterTypeSupLabels: Record<Letter['type'], string> = {
    EcoOdS: "Ordine di Servizio",
    InfoOdS: "Ordine di Servizio",
    UltAccOdS: "Ordine di Servizio",
    NI: "Nota Impresa",
}

export const LetterTypeSubLabels: Record<Letter['type'], string> = {
    EcoOdS: "tecnico/economico",
    InfoOdS: "informazione/comunicazione",
    UltAccOdS: "ultimazione/accettazione lavori",
    NI: "",
}

/** Returns {sender, recipient} ids taken from given job, depending on the type of the given letter */
export function getSenderRecipient<L extends Letter, J extends Job>(letter: Pick<L, 'type'>, job: J): L['type'] extends 'NI' ? {
    sender: J['contractor'],
    recipient: J['buyer'],
} : {
    sender: J['buyer'],
    recipient: J['contractor'],
} {
    if (letter.type === 'NI') {
        return {sender: job.contractor, recipient: job.buyer}
    }
    if (letter.type === 'InfoOdS') {
        return {sender: job.buyer, recipient: job.contractor}
    }
    if (letter.type === 'EcoOdS') {
        return {sender: job.buyer, recipient: job.contractor}
    }
    if (letter.type === 'UltAccOdS') {
        return {sender: job.buyer, recipient: job.contractor}
    }
    const []: never[] = [letter.type]
    throw new Error('Invalid letter type')
}

export type LetterWay =
    | 'contractor2buyer'
    | 'buyer2contractor'

export const LETTER_WAYS: LetterWay[] = [
    'contractor2buyer',
    'buyer2contractor',
]

export function getWay(letter: Pick<Letter, 'type'>): LetterWay {
    if (letter.type === 'NI') {
        return 'contractor2buyer'
    }
    if (letter.type === 'InfoOdS') {
        return 'buyer2contractor'
    }
    if (letter.type === 'EcoOdS') {
        return 'buyer2contractor'
    }
    if (letter.type === 'UltAccOdS') {
        return 'buyer2contractor'
    }
    const []: never[] = [letter.type]
    throw new Error('Invalid letter type')
}

export function getReceived(letter: Pick<Letter, 'signatures' | 'type'>): boolean {
    return !!letter.signatures.find(letter.type === 'NI'
        ? s => s.role !== RoleEnum.Appaltatore
        : s => s.role === RoleEnum.Appaltatore)
}

export function parseExternalRefNumber(ref: string): {
    progressive: number
    postfix36: number | null
    normalized: string
    normalized_progressive: string
} | undefined {
    if (!ref) {
        return undefined
    }
    try {
        const n = parseInt(ref, 10)
        const sp = ref.split('/')
        const s1 = sp[1]
        const p = s1 ? s1.replace(/[^0-9a-zA-Z]/g, '').toUpperCase() : null
        return {
            progressive: n,
            postfix36: p ? parseInt(p.toLowerCase(), 36) : null,
            normalized: n.toString(10).padStart(4, '0') +
                (p === null ? '' : '/' + s1),
            normalized_progressive: n.toString(10).padStart(4, '0'),
        }
    } catch (e) {
        console.warn(e)
        return undefined
    }
}

export type LetterHoles = Record<LetterWay, ReturnType<typeof parseExternalRefNumber>['progressive'][]>
